import React, { useEffect, useState } from 'react';
import { getDatabase, ref, onChildAdded,remove } from "firebase/database";
import { Grid } from "gridjs-react";
import { Container } from "react-bootstrap";
import { CSVLink } from "react-csv";
import Swal from 'sweetalert2';
import Button from "react-bootstrap/Button";
const Historicov = () => {
  const [dataSet, setData] = useState([]);
  
 
  const db = getDatabase();
  const miref = ref(db, "Inventarrios/Australia/Ventas");
  onChildAdded(miref,(DataSnapshot) => {
      var dataSet2 = {
        id: DataSnapshot.key,
        fecha: DataSnapshot.val().fecha,
        codigo: DataSnapshot.val().codigo,
        dependencia: DataSnapshot.val().epps,
        descripcion: DataSnapshot.val().descripcion,
        cantidad: DataSnapshot.val().cantidad,
        recibe: DataSnapshot.val().recibe,
        entrega: DataSnapshot.val().entrega,
      };
      dataSet.push(dataSet2);
    });

    
    const eliminar = ()=>{
      const db = getDatabase();
      remove(ref(db, "Inventarrios/Australia/Ventas/")).then(() => {
          setData([]);
      }); 
    }
    const descargaregistro = () => {
     
      Swal.fire({
        title: 'Do you want to save the changes?',
        showDenyButton: true,
        showCancelButton: true,
        confirmButtonText: 'Limpiar Tabla',
        denyButtonText: `No Limpiar`,
      }).then((result) => {
        /* Read more about isConfirmed, isDenied below */
        if (result.isConfirmed) {
          eliminar();
          Swal.fire('Eliminado!', '', 'success')
        } else if (result.isDenied) {
          Swal.fire('No se elimino', '', 'info')
        }
      })
    };
  

  return (
    <div className="Invenatario">
      <Container className="shopcontainer">
        <h3 className="titulojefe">Historial Salidas</h3>
        <Button variant="info" >
          <CSVLink
            className="green-button"
            variant="danger"
            data={dataSet}
            filename={"ReporteSalidas.csv"}
            
          >
            Descargar Reporte
          </CSVLink>
        </Button>
        <Button className="limpiar" variant="danger" onClick={() => {
              descargaregistro();
            }}>
          Limpiar
        </Button>
        <Grid
          data={dataSet}
          sort={true}
          columns={[
            { name: "id", hidden: true },
            "FECHA",
            "CODIGO",
            "DEPENDENCIA",
            { name: "DESCRIPCION", width: "1200px" },
            "CANTIDAD",
            "ENTREGA",
            "RECIBE",
          ]}
          search={true}
          pagination={{
            enabled: true,
            limit: 6,
          }}
          style={{
            th: {
              background: "#D95252",
              color: "white",
            },
          }}
        />
      </Container>
    </div>
  );
};

export default Historicov;
