import React from 'react'
import { Link } from 'react-router-dom'
import "@fortawesome/fontawesome-free/css/all.min.css"
import 'bootstrap/dist/css/bootstrap.min.css'
import 'mdb-react-ui-kit/dist/css/mdb.min.css'

import Button from 'react-bootstrap/Button';
import { getAuth, signOut } from "firebase/auth";
import firebaseApp from './firebase'
const viewmenu = () => {
    let body = document.getElementById("sidebar");
    body.classList.toggle("body_move");
}
const Empleadosidebar = () => {
    const auth = getAuth(firebaseApp);
    return (
        <div className='sidebarem' id='sidebar'>
            <ul>
                <li>
                <Link><h3>Mina Australia</h3></Link>
                </li>
                <li>
                    <Link onClick={() => viewmenu()} to='/Ventas'><i className="fa-solid fa-truck-moving"></i>Salidas</Link>
                </li>
                <li>
                    <Link onClick={() => viewmenu()} to='/Entradas'><i className="fa-solid fa-dolly"></i>Entradas</Link>
                </li>
                <li>
                    <Link onClick={() => viewmenu()} to='/NuevoPrestamo'><i className="fa-solid fa-people-carry-box"></i>Prestamo</Link>
                </li>
                <li>
                   <Button variant="danger" onClick={() => signOut(auth)} className='cerrarsesion'>Cerrar sesion</Button>
                </li>
               
                
                

            </ul>

        </div>
    )
}

export default Empleadosidebar