import React, { useState } from "react";
import Button from "react-bootstrap/Button";
import { getDatabase, ref, onChildAdded, set, push } from "firebase/database";
import { Grid, _ } from "gridjs-react";
import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";
import moment from "moment/moment";
import Swal from "sweetalert2";
import { Container } from "react-bootstrap";
const Entradas = () => {
  //estados
  const [show, setwow] = useState(false);
  const [ventaupdate, setventaupdate] = useState("default");
  const [cantactual, setnewcant] = useState("");
  const [code, setcode] = useState("");
  const [desc, setdesc] = useState("");
  const [epps, setpepps] = useState("");
  var dataSet = [];
  const db = getDatabase();
  const hiddenmodal = () => {
    setwow(false);
  };

  const miref = ref(db, "Inventarrios/Australia/productos");
  onChildAdded(miref, (DataSnapshot) => {
    var dataSet2 = {
      id: DataSnapshot.key,
      codigo: DataSnapshot.val().codigo,
      dependencia: DataSnapshot.val().epps,
      descripcion: DataSnapshot.val().descripcion,
      cantidad: DataSnapshot.val().cantidad,
    };
    dataSet.push(dataSet2);
  });
  //actualizar cantidad
  function updateData(id, codigo, descripcion, cantidad, eppsu) {
    const db = getDatabase();
    set(ref(db, "Inventarrios/Australia/productos/" + id), {
      cantidad: cantidad,
      codigo: codigo,
      descripcion: eppsu,
      epps: descripcion,
    });
  }
  //registrarventa
  function writedata(
    fecha,
    codigo,
    descripcion,
    cantidad,
    emisor,
    receptor,
    eppsi
  ) {
    const db = getDatabase();
    push(ref(db, "Inventarrios/Australia/Entradas"), {
      fecha: fecha,
      cantidad: cantidad,
      epps: descripcion,
      codigo: codigo,
      descripcion: eppsi,
      emisor: emisor,
      receptor: receptor,
    });
  }
  const viewmodal = (id, codigo, descripcion, cantidad, epps) => {
    setventaupdate(id);
    setnewcant(cantidad);
    setcode(codigo);
    setdesc(descripcion);
    setpepps(epps);
    
    setwow(true);
  };
  const registrarventa = (id, codigo, descripcion, cantidad, eppso) => {
    let entrega = document.getElementById("cantmodal").value;
    let actucant = parseInt(cantidad) + parseInt(entrega);
    let emisor = document.getElementById("emisormodal").value;
    let receptor = document.getElementById("receptormodal").value;
    
    moment.locale("fr");
    const hoy = moment().format("DD/MMM/YYYY");
    console.log(hoy);
    //primero se actualiza el producto
    updateData(id, codigo, descripcion, actucant, eppso);
    //luego guardamos el registro
    writedata(hoy, codigo, descripcion, entrega, emisor, receptor, eppso);
    Swal.fire({
      icon: "success",
      title: "Buen  trabajo 👏",
      text: "Entrada registrada con exito!",
    });
    setwow(false);
  };

  return (
    <div className="Invenatario">
      <Container className="shopcontainer">
        <h3 className="titulojefe">Bienvenido a las Entradas</h3>
        <Grid
          data={dataSet}
          columns={[
            { name: "id", hidden: true },
            "CODIGO",
            "DEPENDENCIA",
            { name: "DESCRIPCION", width: "1200px" },
            "CANTIDAD",
            {
              name: "ACCIONES",
              formatter: (cell, row) => {
                return _(
                  <div className="wrapper text-center">
                    <div className="btn-group">
                      <button
                        className="btnEditar btn btn-info"
                        data-toggle="tooltip"
                        title="Editar"
                        onClick={() =>
                          viewmodal(
                            `${row.cells[0].data}`,
                            `${row.cells[1].data}`,
                            `${row.cells[2].data}`,
                            `${row.cells[4].data}`,
                            `${row.cells[3].data}`
                          )
                        }
                      >
                        <i className="fa-solid fa-truck-ramp-box"></i>{" "}
                        Seleccionar
                      </button>
                    </div>
                  </div>
                );
              },
            },
          ]}
          search={true}
          pagination={{
            enabled: true,
            limit: 6,
          }}
          sort={true}
          style={{
            th: {
              background: "#D95252",
              color: "white",
            },
          }}
        />
      </Container>

      <Modal
        show={show}
        onHide={hiddenmodal}
        animation={false}
        contentClassName="suretoo"
      >
        <Modal.Header closeButton>
          <Modal.Title>Registrar Entrada</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form.Group className="mb-3">
            <Form.Label>Cantidad</Form.Label>
            <Form.Control id="cantmodal" type="number" />
            <Form.Label>Quien entrega</Form.Label>
            <Form.Control id="emisormodal" type="text" />
            <Form.Label>Quien recibe</Form.Label>
            <Form.Control id="receptormodal" type="text" />
          </Form.Group>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="success"
            onClick={() => {
              registrarventa(ventaupdate, code, desc, cantactual, epps);
            }}
          >
            Registrar
          </Button>
          <Button variant="danger" onClick={hiddenmodal}>
            Cancelar
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default Entradas;
