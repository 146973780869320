import React from 'react'
import Button from 'react-bootstrap/Button';
import { getAuth, signOut } from "firebase/auth";
import firebaseApp from './firebase'
import { Container } from 'react-bootstrap';
import { Grid } from 'gridjs-react';
import { getDatabase, ref, onChildAdded } from "firebase/database";
import Image from 'react-bootstrap/Image'
const Shop = () => {
    const auth = getAuth(firebaseApp);
    var dataSet = []
    const db = getDatabase();

    const miref = ref(db, 'Inventarrios/Australia/productos');
    onChildAdded(miref, (DataSnapshot) => {
        var dataSet2 = {
             descripcion: DataSnapshot.val().descripcion, cantidad: DataSnapshot.val().cantidad,

        }
        dataSet.push(dataSet2)
    })

    
    return (

        <Container className='shopcontainer'>
            <h1 className='titulojefe'>Productos Mina Australia</h1>
            <Image src='https://firebasestorage.googleapis.com/v0/b/baky-ef1a3.appspot.com/o/Documetos%2FMinaAustraliaLogi.png?alt=media&token=8b25a8ea-e117-464f-b8ca-b0a8bf3df83f' className="img-fluid" alt="Phone image" />
            
            <Button variant="danger" onClick={() => signOut(auth)} className='cerrarsesion'>Cerrar sesion</Button>


            <Grid
                data={dataSet}
                columns={[ {name:'DESCRIPCION',width:'1200px'}, {name:'CANTIDAD',width:'20%'},]}
                search={true}
                pagination={{
                    enabled: true,
                    limit: 400
                }}
                sort={true}
                style={{
                    th: {
                        background: '#D95252',
                        color: 'white',

                    }

                }}
            />

        </Container>

    )
}

export default Shop