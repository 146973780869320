import React from 'react'
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Barnav from './Barnav';
import Controller from './Controller';
import "gridjs/dist/theme/mermaid.css";
import Ventas from './pages/Ventas';
import Entradas from './pages/Entradas';
import Prestamos from './pages/Prestamos';
import Empleadosidebar from './Empleadosidebar';
const Empleado = () => {
  return (
    <Router>
      <Barnav />

      <div className='flex'>
        <Empleadosidebar />
        <Controller />
        <div className='contenido'>
          <Routes>
            

            <Route path='/Ventas' exact={true} element={<Ventas />} />

            <Route path='/Entradas' exact={true} element={<Entradas />} />

            <Route path='/NuevoPrestamo' exact={true} element={<Prestamos />} />






          </Routes>

        </div>
      </div>

    </Router>
  )
}

export default Empleado