import { initializeApp} from "firebase/app";

const firebaseConfig = {
    apiKey: "AIzaSyD6qi59Q8Qo6gsHXPCRyVCKM4eLpQyMhV0",
    authDomain: "baky-ef1a3.firebaseapp.com",
    databaseURL: "https://baky-ef1a3-default-rtdb.firebaseio.com",
    projectId: "baky-ef1a3",
    storageBucket: "baky-ef1a3.appspot.com",
    messagingSenderId: "670418856961",
    appId: "1:670418856961:web:3fa5a4c22b0c9e010de400",
    measurementId: "G-P05PX5EJZ3"
};
const firebaseApp = initializeApp(firebaseConfig);

export default firebaseApp;