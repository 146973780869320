import React, { useState } from "react";
import {
  getDatabase,
  ref,
  onChildAdded,
  set,
  push,
  remove,
} from "firebase/database";
import { Grid, _ } from "gridjs-react";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import Swal from "sweetalert2";
import { Container } from "react-bootstrap";
import { CSVLink } from "react-csv";

const Inventario = () => {
  // estados
  const [keyid, setkeyid] = useState("");
  const [cant, setcant] = useState("");
  const [epps, setepps] = useState("");
  const [code, setcode] = useState("");
  const [desc, setdesc] = useState("");

  const [btnedit, setbtnedit] = useState(true);
  const [btnew, setbtnew] = useState(true);
  const [show, setShow] = useState(false);
  const [modalwarning, setmodalwarning] = useState(false);
  const [confirmdelete, setdelete] = useState("noname");
  //CREATE
  function writeUserData(codigo, descripcion, cantidad, epps) {
    const db = getDatabase();
    push(ref(db, "Inventarrios/Australia/productos"), {
      cantidad: cantidad,
      epps: epps,
      codigo: codigo,
      descripcion: descripcion,
    });
  }
  const agregarproducto = () => {
    setkeyid("");
    setcant("");
    setcode("");
    setdesc("");

    setbtnedit(true);
    setbtnew(false);
    setShow(true);
  };
  const save = () => {
    var modalcodigo = document.getElementById("codigop").value;
    var modaldes = document.getElementById("modaldes").value;
    var modalepps = document.getElementById("modalepps").value;

    var modalcant = parseInt(document.getElementById("modalcant").value);

    writeUserData(modalcodigo, modaldes, modalcant, modalepps);
    Swal.fire({
      icon: "success",
      title: "Buen  trabajo 👏",
      text: "Producto agregado con exito!",
    });

    setShow(false);
  };

  const handleClose = () => {
    setShow(false);
  };
  //update

  const editchange = () => {
    var modalid = document.getElementById("modalid").value;
    var modalcodigo = document.getElementById("codigop").value;
    var modaldes = document.getElementById("modaldes").value;
    var modalepps = document.getElementById("modalepps").value;

    var modalcant = parseInt(document.getElementById("modalcant").value);

    updateData(modalid, modalcodigo, modaldes, modalcant, modalepps);
    Swal.fire({
      icon: "success",
      title: "Buen  trabajo 👏",
      text: "Producto Editado con exito!",
    });

    setShow(false);
  };
  
  function updateData(id, codigo, descripcion, cantidad, epps) {
    const db = getDatabase();
    set(ref(db, "Inventarrios/Australia/productos/" + id), {
      cantidad: cantidad,
      codigo: codigo,
      epps: epps,
      descripcion: descripcion,
    });
  }

  //delete

  const warningview = (id) => {
    setdelete(id);

    setmodalwarning(true);
  };
  const hidewarning = () => {
    setmodalwarning(false);
  };
  const handleShow = (id, codigo, eppsi, descripcion, cantidad) => {
    setkeyid(id);
    setcant(cantidad);
    setcode(codigo);
    setdesc(descripcion);
    setepps(eppsi);
    console.log(id, codigo, descripcion, eppsi);
    setbtnew(true);
    setbtnedit(false);
    setShow(true);
  };

  const deletewarning = (id) => {
    deletedata(id);
  };
  function deletedata(id) {
    const db = getDatabase();
    remove(ref(db, "Inventarrios/Australia/productos/" + id));
    Swal.fire({
      icon: "success",
      title: "Eliminado",
      text: "Producto Eliminado con exito!",
    });
    setmodalwarning(false);
  }
  //read
  var dataSet = [];
  const db = getDatabase();

  const miref = ref(db, "Inventarrios/Australia/productos");
  onChildAdded(miref, (DataSnapshot) => {
    var dataSet2 = {
      id: DataSnapshot.key,
      codigo: DataSnapshot.val().codigo,
      dependencia: DataSnapshot.val().epps,
      descripcion: DataSnapshot.val().descripcion,
      cantidad: DataSnapshot.val().cantidad,
    };
    dataSet.push(dataSet2);
  });

  return (
    <div className="Invenatario">
      <Container className="shopcontainer">
        <h3 className="titulojefe">Bienvenido al inventario</h3>
        <Button variant="primary" onClick={agregarproducto}>
          Agregar nuevo producto
        </Button>
        <Button variant="info" className="limpiar">
          <CSVLink
            className="green-button"
            data={dataSet}
            filename={"Productos.csv"}
            
          >
            Download Data
          </CSVLink>
        </Button>

        <Grid
          data={dataSet}
          columns={[
            { name: "id", hidden: true },
            "CODIGO",
            "DEPENDENCIA",
            { name: "DESCRIPCION", width: "1200px" },
            "CANTIDAD",
            {
              name: "ACCIONES",
              formatter: (cell, row) => {
                return _(
                  <div className="wrapper text-center">
                    <div className="btn-group">
                      <button
                        className="btnEditar btn btn-primary"
                        data-toggle="tooltip"
                        title="Editar"
                        onClick={() =>
                          handleShow(
                            `${row.cells[0].data}`,
                            `${row.cells[1].data}`,
                            `${row.cells[2].data}`,
                            `${row.cells[3].data}`,
                            `${row.cells[4].data}`
                          )
                        }
                      >
                        <i className="fa-solid fa-pen-to-square"></i>
                      </button>
                      <button
                        className="btnBorrar btn btn-danger"
                        data-toggle="tooltip"
                        title="Borrar"
                        onClick={() => warningview(`${row.cells[0].data}`)}
                      >
                        <i className="fa-solid fa-trash-can"></i>
                      </button>
                    </div>
                  </div>
                );
              },
            },
          ]}
          search={true}
          pagination={{
            enabled: true,
            limit: 6,
          }}
          sort={true}
          style={{
            th: {
              background: "#D95252",
              color: "white",
            },
          }}
        />
      </Container>

      <Modal
        show={modalwarning}
        onHide={hidewarning}
        contentClassName="sure"
        animation={false}
      >
        <Modal.Header closeButton>
          <Modal.Title>¿Deseas eliminar este producto?</Modal.Title>
        </Modal.Header>
        <Modal.Footer>
          <Button variant="danger" onClick={hidewarning}>
            Cancelar
          </Button>
          <Button
            variant="success"
            onClick={() => deletewarning(confirmdelete)}
          >
            Borrar
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal
        show={show}
        onHide={handleClose}
        contentClassName="formularioxd"
        animation={false}
      >
        <Modal.Header closeButton>
          <Modal.Title>Agregar Producto</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form.Group className="mb-3">
            <Form.Label>Completa todos los campos</Form.Label>
            <Form.Control id="modalid" defaultValue={keyid} hidden />
          </Form.Group>

          <Form.Group className="mb-3">
            <Form.Label>Codigo</Form.Label>
            <Form.Control id="codigop" defaultValue={code} autoFocus />
          </Form.Group>
          <Form.Group className="mb-3">
            <Form.Label>Dependencia</Form.Label>
            <Form.Control id="modalepps" type="text" defaultValue={epps} />
          </Form.Group>
          <Form.Group className="mb-3">
            <Form.Label>Descripción</Form.Label>
            <Form.Control
              id="modaldes"
              defaultValue={desc}
              placeholder="Describe tu producto"
            />
          </Form.Group>
          <Form.Group className="mb-3"></Form.Group>
          <Form.Group className="mb-3">
            <Form.Label>Cantidad</Form.Label>
            <Form.Control id="modalcant" type="number" defaultValue={cant} />
          </Form.Group>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="danger" onClick={handleClose}>
            Cancelar
          </Button>
          <Button variant="primary" onClick={editchange} hidden={btnedit}>
            Editar
          </Button>
          <Button variant="success" onClick={save} hidden={btnew}>
            Agregar
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default Inventario;
