import React from 'react'
import "gridjs/dist/theme/mermaid.css";
import { getDatabase, ref, onChildAdded } from "firebase/database";
import Admin from './Admin';
import Empleado from './Empleado';
import Shop from './Shop';

const Main = ({ usuarioGlobal }) => {
  //consultas previas
  
  return (
    <div>

      {usuarioGlobal.rol === "administrador" ? <Admin /> :usuarioGlobal.rol === "Jefe" ? <Shop/> :<Empleado/> }
      

    </div>

  )
}

export default Main