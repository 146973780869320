import React, { useState } from "react";
import { getDatabase, ref, onChildAdded } from "firebase/database";
import './App.css';
import firebaseApp from '../src/components/firebase';
import { PrimerComponente } from './components/Loging';
import { getAuth, onAuthStateChanged } from "firebase/auth";
import Main from "./components/Main";
import { doc, getDoc, getFirestore } from "firebase/firestore";
const auth = getAuth(firebaseApp);
const firestore = getFirestore(firebaseApp)

function App() {
  const [usuarioGlobal, setUsuarioGlobal] = useState(null);
  var dataSet = []
  const db = getDatabase();

  const miref = ref(db, 'Inventarrios/Australia/productos');
  onChildAdded(miref, (DataSnapshot) => {
    var dataSet2 = {
      id: DataSnapshot.key, codigo: DataSnapshot.val().codigo, descripcion: DataSnapshot.val().descripcion, cantidad: DataSnapshot.val().cantidad,

    }
    dataSet.push(dataSet2)
  })

  async function getrol(uid) {
    const docuref = doc(firestore, `Australiausuarios/${uid}`);
    const docucifrada = await getDoc(docuref);
    const infofinal = docucifrada.data().rol;
    return infofinal;
  }


  function establecerestado(usuarioFirebase) {
    getrol(usuarioFirebase.uid).then((rol) => {
      const userdata = {
        uid: usuarioFirebase.uid,
        email: usuarioFirebase.email,
        rol: rol,
      }
      setUsuarioGlobal(userdata)

    })




  }


  onAuthStateChanged(auth, (usuarioFirebase) => {
    if (usuarioFirebase) {
      //inicio sesion
      if (!usuarioGlobal) {
        establecerestado(usuarioFirebase)
      }

    }
    else {
      setUsuarioGlobal(null);
    }
  })

  return <>
    {usuarioGlobal ? <Main usuarioGlobal={usuarioGlobal} /> : <PrimerComponente />}


  </>
}

export default App;
