import React from 'react';
import firebaseApp from './firebase';
import Container from 'react-bootstrap/Container';
import { Form } from 'react-bootstrap';
import Image from 'react-bootstrap/Image'
import 'bootstrap/dist/css/bootstrap.min.css';
import 'mdb-react-ui-kit/dist/css/mdb.min.css';
import "@fortawesome/fontawesome-free/css/all.min.css";
import Swal from 'sweetalert2';
import { MDBContainer, MDBCol, MDBRow, MDBBtn, MDBInput, MDBCheckbox } from 'mdb-react-ui-kit';
import { getAuth, signInWithEmailAndPassword } from "firebase/auth";
const auth = getAuth(firebaseApp);

export const PrimerComponente = () => {
    async function submitHandler(e) {
        e.preventDefault();
        const correo = e.target.correo.value;
        const contra = e.target.contraseña.value;
        var p = signInWithEmailAndPassword(auth, correo, contra);
        


        p.then(function (value) {
            
        }, function (reason) {
            Swal.fire({
                icon: 'error',
                title: 'Algo salio mal',
                text: 'Revisa que el correo y la contraseña coincidan!',
                
              })
        });


    }
    return (
        <Container>
            
            <MDBContainer fluid className="p-3 my-5">

                <MDBRow className='formlogin'>

                    <MDBCol col='10' md='6'>
                        <Image src='https://firebasestorage.googleapis.com/v0/b/baky-ef1a3.appspot.com/o/Documetos%2FlogoAustralia.png?alt=media&token=9a5e85a4-c201-4f1a-8e6c-622aef5a197f' className="img-fluid" alt="Phone image" />
                    </MDBCol>

                    <MDBCol col='10' md='6' >
                        <Form onSubmit={submitHandler}>
                            <MDBInput
                                id="correo"
                                autoFocus
                                wrapperClass='mb-4' label='Correo electronico' type='email' size="lg" />
                            <MDBInput
                                id="contraseña"
                                wrapperClass='mb-4' label='Contraseña' type='password' size="lg" />


                            <div className="d-flex justify-content-between mx-4 mb-4">
                                <MDBCheckbox name='flexCheck' value='' id='flexCheckDefault' label='Recuerdame' />
                                <a href="!#">Olvidaste tu contraseña?</a>
                            </div>

                            <MDBBtn className="mb-4 w-100" size="lg">Acceder</MDBBtn>
                        </Form>
                    </MDBCol>

                </MDBRow>

            </MDBContainer>
        </Container>


    )
}
