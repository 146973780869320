import React from 'react'
const viewmenu = () => {
    let body = document.getElementById("sidebar");
    
    body.classList.toggle("body_move");
    
    

}
const Controller = () => {
  return (
    <div><i className="fas fa-bars" id="btn_open" onClick={() => viewmenu()}></i></div>
  )
}

export default Controller