import React, { useState } from "react";
import Button from "react-bootstrap/Button";
import { getDatabase, ref, onChildAdded, set, remove } from "firebase/database";
import { Grid, _ } from "gridjs-react";
import Modal from "react-bootstrap/Modal";
import { Container } from "react-bootstrap";
import { CSVLink } from "react-csv";
import Swal from 'sweetalert2';
const Hisprestamos = () => {
  
  //estados
  const [show, setwow] = useState(false);
  const [ventaupdate, setventaupdate] = useState("default");
  const [cantactual, setnewcant] = useState("");
  const [code, setcode] = useState("");
  const [desc, setdesc] = useState("");

  const [fechi, setfecha] = useState("");
  const [estadi, setestado] = useState("");
  const [prestami, setprestamo] = useState("");
  const [dataSet, setData] = useState([]);

  const db = getDatabase();
  const hiddenmodal = () => {
    setwow(false);
  };

  const miref = ref(db, "Inventarrios/Australia/Prestamos");
  onChildAdded(miref, (DataSnapshot) => {
    var dataSet2 = {
      id: DataSnapshot.key,
      codigo: DataSnapshot.val().codigo,
      descripcion: DataSnapshot.val().descripcion,
      cantidad: DataSnapshot.val().cantidad,
      estado: DataSnapshot.val().estado,
      prestamo: DataSnapshot.val().prestamop,
      fecha: DataSnapshot.val().fecha,
    };
    dataSet.push(dataSet2);
  });
  //actualizar cantidad
  function updateData(
    id,
    codigo,
    descripcion,
    cantidad,
    estado,
    fecha,
    prestamo
  ) {
    const db = getDatabase();
    set(ref(db, "Inventarrios/Australia/Prestamos/" + id), {
      cantidad: cantidad,
      codigo: codigo,
      descripcion: descripcion,
      estado: estado,
      fecha: fecha,
      prestamop: prestamo,
    });
  }
  const viewmodal = (
    id,
    fecha,
    codigo,
    descripcion,
    cantidad,
    estado,
    prestamo
  ) => {
    setventaupdate(id);
    setfecha(fecha);
    setnewcant(cantidad);
    setcode(codigo);
    setdesc(descripcion);
    setestado(estado);
    setprestamo(prestamo);
    setwow(true);
  };
  const registrarventa = (
    id,
    codigo,
    descripcion,
    cantidad,
    estado,
    fecha,
    prestamo
  ) => {
    //primero se actualiza el producto
    updateData(id, codigo, descripcion, cantidad, estado, fecha, prestamo);

    setwow(false);
  };
  const eliminar = ()=>{
    const db = getDatabase();
    remove(ref(db, "Inventarrios/Australia/Prestamos/")).then(() => {
        setData([]);
    }); 
  }
  const descargaregistro = () => {
   
    Swal.fire({
      title: 'Do you want to save the changes?',
      showDenyButton: true,
      showCancelButton: true,
      confirmButtonText: 'Limpiar Tabla',
      denyButtonText: `No Limpiar`,
    }).then((result) => {
      /* Read more about isConfirmed, isDenied below */
      if (result.isConfirmed) {
        eliminar();
        Swal.fire('Eliminado!', '', 'success')
      } else if (result.isDenied) {
        Swal.fire('No se elimino', '', 'info')
      }
    })
  };

  return (
    <div className="Invenatario">
      
      <Container className="shopcontainer">
        <h3 className="titulojefe">Prestamos Registrados xd</h3>
        <Button variant="info" >
          <CSVLink
            className="green-button"
            variant="danger"
            data={dataSet}
            filename={"reporte.csv"}
           
          >
            Download Data
          </CSVLink>
        </Button>
        <Button className="limpiar" variant="danger"  onClick={() => {
              descargaregistro();
            }}>
          Limpiar
        </Button>
        <Grid
          data={dataSet}
          columns={[
            { name: "id", hidden: true },
            "FECHA",
            { name: "CODIGO", hidden: true },
            { name: "DESCRIPCION", width: "1200px" },
            "CANTIDAD",
            "ESTADO",
            "PRESTAMO",
            {
              name: "ACCIONES",
              formatter: (cell, row) => {
                return _(
                  <div className="wrapper text-center">
                    <div className="btn-group">
                      <button
                        className="btnEditar btn btn-secondary"
                        data-toggle="tooltip"
                        title="Editar"
                        onClick={() =>
                          viewmodal(
                            `${row.cells[0].data}`,
                            `${row.cells[1].data}`,
                            `${row.cells[2].data}`,
                            `${row.cells[3].data}`,
                            `${row.cells[4].data}`,
                            `${row.cells[5].data}`,
                            `${row.cells[6].data}`
                          )
                        }
                      >
                        <i class="fa-solid fa-person-circle-check"></i>{" "}
                        Seleccionar
                      </button>
                    </div>
                  </div>
                );
              },
            },
          ]}
          search={true}
          pagination={{
            enabled: true,
            limit: 6,
          }}
          sort={true}
          style={{
            th: {
              background: "#D95252",
              color: "white",
            },
          }}
        />
      </Container>

      <Modal
        show={show}
        onHide={hiddenmodal}
        animation={false}
        contentClassName="suretoo"
      >
        <Modal.Header closeButton>
          <Modal.Title>¿Marcar como devuelto?</Modal.Title>
        </Modal.Header>
        <Modal.Footer>
          <Button variant="danger" onClick={hiddenmodal}>
            Cancelar
          </Button>
          <Button
            variant="success"
            onClick={() => {
              registrarventa(
                ventaupdate,
                code,
                desc,
                cantactual,
                "Devuelto",
                fechi,
                prestami
              );
            }}
          >
            Aceptar
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default Hisprestamos;
