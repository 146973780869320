import React, { useState } from 'react'
import Button from 'react-bootstrap/Button';
import { getDatabase, ref, onChildAdded, push } from "firebase/database";
import { Grid, _ } from "gridjs-react";
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import moment from 'moment/moment';
import Swal from 'sweetalert2';
import { Container } from 'react-bootstrap';
const Prestamos = () => {
    //estados
    const [show, setwow] = useState(false);
    const [ventaupdate, setventaupdate] = useState('default');
    const [cantactual, setnewcant] = useState('');
    const [code, setcode] = useState('');
    const [desc, setdesc] = useState('');

    var dataSet = []

    const db = getDatabase();
    const hiddenmodal = () => {
        setwow(false)


    }

    const miref = ref(db, 'Inventarrios/Australia/productos');
    onChildAdded(miref, (DataSnapshot) => {
        var dataSet2 = {
            id: DataSnapshot.key, codigo: DataSnapshot.val().codigo, descripcion: DataSnapshot.val().descripcion, cantidad: DataSnapshot.val().cantidad,
            precio: DataSnapshot.val().precio,
        }
        dataSet.push(dataSet2)
    })
    //actualizar cantidad

    //registrarventa
    function writedata(fecha, codigo, descripcion, cantidad, prestamo) {
        const db = getDatabase();
        push(ref(db, 'Inventarrios/Australia/Prestamos'), {
            fecha: fecha,
            cantidad: cantidad,
            codigo: codigo,
            descripcion: descripcion,
            prestamop: prestamo,
            estado: 'Prestado'
        });
    }
    const viewmodal = (id, codigo, descripcion, cantidad, precio) => {


        setventaupdate(id)
        setnewcant(cantidad)
        setcode(codigo)
        setdesc(descripcion)

        setwow(true)

    }
    const registrarventa = (id, codigo, descripcion, cantidad) => {
        let entrega = document.getElementById('cantmodalp').value


        let prestamo = document.getElementById('prestamomodal').value


        const hoy = moment().format('DD/MMM/YYYY')

        //primero se actualiza el producto

        //luego guardamos el registro
        writedata(hoy, codigo, descripcion, entrega, prestamo)
        Swal.fire({
            icon: 'success',
            title: 'Buen  trabajo 👏',
            text: 'Prestamo registrado con exito!',

        })
        setwow(false)

    }

    return (
        <div className='Invenatario'>
            <Container className='shopcontainer'>
                <h3 className='titulojefe'>Bienvenido a Prestamos</h3>
                <Grid

                    data={dataSet}
                    columns={[{ name: 'id', hidden: true }, 'CODIGO', { name: 'DESCRIPCION', width: '1200px' }, { name: 'PRECIO', hidden: true }, 'CANTIDAD', { name: 'ACCIONES', formatter: (cell, row) => { return _(<div className='wrapper text-center'><div className='btn-groupito'><button className='btnEditar btn btn-secondary' data-toggle='tooltip' title='Seleccionar' onClick={() => viewmodal(`${row.cells[0].data}`, `${row.cells[1].data}`, `${row.cells[2].data}`, `${row.cells[4].data}`, `${row.cells[3].data}`)}><i className="fa-solid fa-person-circle-plus"></i> Seleccionar</button></div></div>) } }]}
                    search={true}
                    pagination={{
                        enabled: true,
                        limit: 6,
                    }}
                    sort={true}
                    style={{
                        th: {
                            background: '#D95252',
                            color: 'white'
                        }
                    }}
                />


            </Container>


            <Modal show={show} onHide={hiddenmodal} animation={false} contentClassName='sure3' >
                <Modal.Header closeButton>
                    <Modal.Title>Prestamo</Modal.Title>

                </Modal.Header>
                <Modal.Body>

                    <Form.Group className="mb-3" >

                        <Form.Label>Cantidad</Form.Label>
                        <Form.Control


                            id='cantmodalp'

                            type='number'

                        />
                        <Form.Label>Se le presta a:</Form.Label>
                        <Form.Control
                            id='prestamomodal'

                            type='text'
                        />

                    </Form.Group>




                </Modal.Body>
                <Modal.Footer >

                    <Button variant="success" onClick={() => { registrarventa(ventaupdate, code, desc, cantactual) }}>
                        Registrar
                    </Button>
                    <Button variant='danger' onClick={hiddenmodal}>Cancelar</Button>


                </Modal.Footer>

            </Modal>
        </div>
    )
}

export default Prestamos