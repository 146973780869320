import React from 'react'
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Barnav from './Barnav';
import Controller from './Controller';
import Inventario from './pages/Inventario';
import Sidebar from './Sidebar';
import "gridjs/dist/theme/mermaid.css";
import Ventas from './pages/Ventas';
import Historicov from './pages/Historicov';
import Entradas from './pages/Entradas';
import Historicoe from './pages/Historicoe';
import Prestamos from './pages/Prestamos';
import Hisprestamos from './pages/Hisprestamos';
const Admin = () => {
  return (
    <Router>
      <Barnav />

      <div className='flex'>
        <Sidebar />
        <Controller />
        <div className='contenido'>
          <Routes>
           
            <Route path='/Inventario' exact={true} element={<Inventario />} />
            <Route path='/Ventas' exact={true} element={<Ventas />} />
            <Route path='/HistorialVentas' exact={true} element={<Historicov />} />
            <Route path='/Entradas' exact={true} element={<Entradas />} />
            <Route path='/HistorialEntradas' exact={true} element={<Historicoe />} />
            <Route path='/NuevoPrestamo' exact={true} element={<Prestamos/>} />
            <Route path='/HistorialPrestamos' exact={true} element={<Hisprestamos/>} />
            
            



          </Routes>

        </div>
      </div>

    </Router>
  )
}

export default Admin